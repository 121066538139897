import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputLeftElement,
  FormErrorMessage,
  InputGroup,
  Select,
} from '@chakra-ui/react';

import {
  Container,
  SkillsSection,
  CompetencesSection,
  CompetencesWrapper,
  Competences,
  Competence,
  AvailableCompetence,
  Top,
  TopBody,
  TopBodySubtext,
  EditButtonContainer,
  CompetenceButton,
  Line,
  Indicators,
  Indicator,
  IndicatorBody,
  IndicatorDot,
  IndicatorHeader,
  IndicatorTest,
  EmptyBlock,
  ErrorBlock,
  NoData,
} from './styles';

import SearchIcon from '@/assets/icons/search.svg';
import { CrossAdd, CrossDelete } from '@/UI';

import {
  searchCompetences,
  // editCompetences,
} from '@/features/employee';
import { getCompetences } from '@/entities/competence';
import { createCompetenceVacancy } from '@/features/competences'

import { SubmitHandler, useForm } from 'react-hook-form';
import { IVacanci, Skill, useDebounce } from '@/shared';
import { SkillsWrapper } from '@/entities/vacanci/ui/styles';
import { SkillList, SkillButton } from '@/features/employee/ui/EditSkillsPopup/EditSkillsPopup';
import { searchSkills } from '@/features/employee';
import { createVacancy } from '@/features/vacanci';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';
import { companyVacancy } from '@/features/vacanci';
import { grades } from '@/shared';

interface IFormFileds {
  title: string;
  description: string;
  parent: number;
  skill: any[];
  grade: string;
  is_public: boolean;
}

interface ICreateVacancyPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateVacancyPopup: React.FC<ICreateVacancyPopupProps> = ({ isOpen, onClose }) => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const levels: any = {
    'Слабый': 'Beginner',
    'Уверенный': 'Intermediate',
    'Сильный': 'Master',
  }
  const reverseLevels: any = {
    'Beginner': 'Слабый',
    'Intermediate': 'Уверенный',
    'Master': 'Сильный',
  }

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];
  const company = JSON.parse(localStorage.getItem('company'));

  const [selectedGrade, setSelectedGrade] = useState<string | undefined>();

  // Skills
  const [skills, setSkills] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [searchSoftTerm, setSearchSoftTerm] = useState('');
  const [searchHardTerm, setSearchHardTerm] = useState('');
  const debouncedSearchSoftTerm = useDebounce(searchSoftTerm, 500);
  const debouncedSearchHardTerm = useDebounce(searchHardTerm, 500);

  // Competences
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [competences, setCompetences] = useState([]);
  const [availableCompetences, setAvailableCompetences] = useState([]);
  const [isAwaiting, setIsAwaiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const messageWaitTime: number = 5000;

  // Skills
  function addSkill(addedSkill: any) {
    let flag = true;
    skills.forEach((s) => {
      if (s.name === addedSkill.name) {
        flag = false;
      } 
    })
    if (flag) {
      setSkills([...skills, addedSkill]);
    }
  } 

  function removeSkill(removedSkill: any) {
    const updatedSkills = skills.filter((skill: any) => {
      return skill?.id !== removedSkill?.id;
    })
    setSkills(updatedSkills);
  }

  function handleSoftSearch(type: string) {
    searchSkills(debouncedSearchSoftTerm, type)
    .then((res: any) => {
      setSoftSkills(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  function handleHardSearch(type: string) {
    searchSkills(debouncedSearchHardTerm, type)
    .then((res: any) => {
      setHardSkills(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (searchSoftTerm) {
      handleSoftSearch('Soft');
    }
  }, [debouncedSearchSoftTerm])

  useEffect(() => {
    if (searchHardTerm) {
      handleHardSearch('Hard');
    }
  }, [debouncedSearchHardTerm])

  
  // Competences
  function handleGetCompetences() {
    setIsLoading(true);

    getCompetences()
      .then((response: any) => {
        if (response?.status === 200) {
          let competencesToSet: any = [];
          
          for (const competence of response.data) {
            if (
                !competences.map((instance: any) => {return instance.competence.name;}).includes(
                competence.name
              )
            ) {
              competencesToSet.push(competence);
            }
          }
          setAvailableCompetences(competencesToSet);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  function handleSearchCompetences() {
    setIsLoading(true);

    searchCompetences(debouncedSearchQuery)
      .then((response: any) => {
        if (response?.status === 200) {
          let competencesToSet: any = [];
          for (const competence of response.data) {
            if (!competences.map(
              (instance) => {
                return instance.competence.name
              }
            ).includes(competence.name)) {
              competencesToSet.push(competence);
            }
          }
          setAvailableCompetences(competencesToSet);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  function get_competence_level(
    grades: any,
    target_indicators_levels: any,
  ) {
    const level_gradation_mapping: any = {
      Beginner: 0,
      Intermediate: 1,
      Master: 2,
    };

    try {
      let target_indicators_levels_mapping: any = {};

      for (const target_indicator_level of target_indicators_levels) {
        target_indicators_levels_mapping[
          target_indicator_level.indicator.name
        ] = target_indicator_level.score ? get_level_by_score(
          target_indicator_level.score,
          target_indicator_level.indicator.gradations,
        ) : target_indicator_level.level
      }

      let competence_level: string = 'Beginner';

      const grades_array = [
        ...grades.filter(
          (grade: any) => {
            if (grade.name === 'Beginner') {
              return grade;
            }
          }
        ),
        ...grades.filter(
          (grade: any) => {
            if (grade.name === 'Intermediate') {
              return grade;
            }
          }
        ),
        ...grades.filter(
          (grade: any) => {
            if (grade.name === 'Master') {
              return grade;
            }
          }
        ),
      ];

      for (const grade of grades_array) {
          let indicators_levels_mapping: any = {};

          for (const indicator_level of grade.indicators_levels) {
            indicators_levels_mapping[
                indicator_level.indicator.name
            ] = indicator_level.level;
          }

          let level_flag: boolean = true;

          for (const indicator_name of Object.keys(indicators_levels_mapping)) {
              if (level_gradation_mapping[
                target_indicators_levels_mapping[indicator_name]
                ] < level_gradation_mapping[indicators_levels_mapping[indicator_name]]
              ) {
                level_flag = false;
                break;
              }
          }

          if (!level_flag) {
            break
          }

          competence_level = grade.name;
      }
      
      return competence_level;
    }
    catch(e) {
      console.log(e);
      return '';
    }
  }

  function get_level_by_score(score: number, gradations: any) {
    if (!score || !gradations) {
      return '';
    }

    const level: string = 'Master';

    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    }

    for (let i = 0; i < gradations.length; i++) {
      if (score < gradations[i]) {
        return gradation_level_mapping[i];
      }
    }

    return level;
  }

  function get_score_by_level(level: string, gradations: any) {
    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    };

    let score: number = 100;

    for (let i = 0; i < gradations.length; i++) {
      if (gradation_level_mapping[i] === level) {
        return gradations[i] - 1;
      }
    }

    return score;
  }

  useEffect(() => {
    if (debouncedSearchQuery !== '') {
      handleSearchCompetences();
      console.log(competences);
    }
    else {
      setAvailableCompetences([]);
      setIsLoading(false);
      // handleGetCompetences();
    }
  }, [debouncedSearchQuery]);

  function addCompetence(addedCompetence: any) {
    setAvailableCompetences(
      availableCompetences.filter(
        (competence: any) => {
          if (addedCompetence.name !== competence.name) {
            return competence;
          }
        }
      )
    );

    let modifiedAddedCompetence: any = {
      competence: addedCompetence,
      level: '',
      indicators_levels: [],
    };

    for (const indicator of addedCompetence.indicators) {
      modifiedAddedCompetence.indicators_levels.push(
        {
          indicator: indicator,
          level: '',
        }
      )
    }

    setCompetences([...competences, modifiedAddedCompetence]);
  }

  function updateCompetence(
    value: string,
    isCompetence: boolean,
    name: any,
    indicatorName: any = '',
  ) {
    setCompetences(
      competences.map((instance) => {
        let instanceToReturn: any = instance;
        if (instance.competence.name === name) {
          let updatedInstance: any;

          if (isCompetence) {
            updatedInstance = instance;
            updatedInstance.level = levels[value];
          }
          else {
            updatedInstance = instance;
            let updatedIndicatorsInstances = instance.indicators_levels;

            updatedIndicatorsInstances = updatedIndicatorsInstances.map(
              (indicatorInstance: any) => {
                let indicatorInstanceToReturn: any = indicatorInstance;

                if (indicatorInstance.indicator.name === indicatorName) {
                  let updatedIndicatorInstance = indicatorInstance;
                  updatedIndicatorInstance.level = levels[value];
                  indicatorInstanceToReturn = updatedIndicatorInstance;
                }

                return indicatorInstanceToReturn;
              }
            )
          }
          instanceToReturn = updatedInstance;
        }
        return instanceToReturn;
      })
    );
  }

  function removeCompetence(removedCompetence: any) {
    setCompetences(
      competences.filter(
        (instance: any) => {
          if (removedCompetence.competence.name !== instance.competence.name) {
            return instance;
          }
        }
      )
    )
  }

  useEffect(() => {
    if (searchQuery !== '') {
      setIsLoading(true);
    }
  }, [searchQuery]);

  const competenceValidator = {
    checkValidation(instance: any) {
      let tempErrorMessages: any[] = [];

      for (const indicatorInstance of instance.indicators_levels) {
        if (indicatorInstance.level === '') {
          setErrorMessages(['']);
          const message = 'Укажите уровни индикаторов';
          if (!tempErrorMessages.includes(message)) {
            tempErrorMessages.push(message);
          }
        }
      }

      setErrorMessages(tempErrorMessages);

      if (tempErrorMessages.length > 0) {
        this.timeoutId = setTimeout(() => {
          setErrorMessages([]);
        }, messageWaitTime);
        return false;
      }

      return true;
    },

    validateCompetence(instance: any) {
      if (this.timeoutId !== undefined) {
        clearTimeout(this.timeoutId);
      }
      return this.checkValidation(instance);
    }
  }

  // useEffect(() => {
  //   handleGetCompetences();
  // }, [competences]);

  function formCompetenceVacancyInstance(instance: any, vacancyId: number) {
    const response: any = {
      competence: instance.competence.id,
      vacancy: vacancyId,
      indicators_levels: instance.indicators_levels.map(
        (indicator_level: any) => {
          return {
            indicator: indicator_level.indicator.id,
            level: indicator_level.level,
            score: get_score_by_level(
              indicator_level.level,
              indicator_level.indicator.gradations,
            ),
          }
        }
      ),
    }
    return response;
  }

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    setIsAwaiting(true);

    data.skill = skills;
    data.parent = companyId;
    data.grade = selectedGrade;
    data.is_public = false;

    let competencesAreValidated: boolean = true;

    if (company.competence_system) {
      for (const competence of competences) {
        competencesAreValidated = competenceValidator.checkValidation(
          competence
        );
      }
      if (competencesAreValidated) {
        createVacancy(data)
          .then((vacancyResponse) => {
            const vacancy: IVacanci = vacancyResponse.data;
            const competencesVacancies = competences.map(
              (instance: any) => {
                return formCompetenceVacancyInstance(instance, vacancy.id);
              }
            )
            console.log(competencesVacancies);
            createCompetenceVacancy(competencesVacancies, vacancy.id)
              .then((competencesVacanciesResponse) => {
                vacancy.competences = competences;

                companyVacancy(company.id, vacancy.id)
                  .then((companyVacancyResponse) => {
                    vacanciesState.setCompanyVacancies([...vacanciesState.vacancies.company, vacancy]);
                    onClose();
                  })
                  .catch((e) => {
                    console.log(e);
                  })
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
    else {
      createVacancy(data)
        .then((res) => {
          const vacancy: IVacanci = res.data;
          companyVacancy(companyId, res.data.id)
            .then((res) => {
              vacanciesState.setCompanyVacancies([...vacanciesState.vacancies.company, vacancy]);
              onClose();
            })
            .catch((e) => {
              console.log(e);
            })
        })
        .catch((e) => {
          console.log(e);
        })
    }

    setIsAwaiting(false);
  }

  return (
    <Modal size={'2xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Создание должности</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <FormControl isInvalid={Boolean(errors.title)}>
                <FormLabel>Название должности *</FormLabel>
                <Input
                  id='title'
                  {...register('title', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Frontend developer'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.title && <>{errors.title.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl>
                <FormLabel>Грейд</FormLabel>
                  <Select
                    variant='flushed'
                    onChange={(e) => setSelectedGrade(e.target.value)}
                    value={selectedGrade}
                    defaultValue=""
                  >
                    <option hidden disabled value="">Без грейда</option>
                    {grades.map((option) =>
                      <option key={option} value={option}>{option}</option>
                    )}
                  </Select>
                <FormErrorMessage>{errors.grade && <>{errors.grade.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.title)}>
                <FormLabel>Описание должности *</FormLabel>
                <Input
                  id='description'
                  {...register('description', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Создавать интерфейсы'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.title && <>{errors.title.message}</>}</FormErrorMessage>
              </FormControl>
              <Container>
                {!company.competence_system && 
                  <FormLabel>
                    Навыки
                  </FormLabel>
                }
                {company.competence_system ? 
                  <CompetencesSection>
                    <CompetencesWrapper>
                      <p>
                        Компетенции должности
                      </p>
                      {competences && competences.length ?
                          <Competences>
                            {competences.map((instance) => 
                              <Competence>
                                <Top>
                                  <TopBody>
                                    <div>
                                      <h5>{instance.competence.name}</h5>
                                    </div>
                                    {/* <TopBodySubtext>
                                      {instance.competence.level ? instance.competence.level : 'Предпросмотр уровня'}
                                    </TopBodySubtext> */}
                                  </TopBody>
                                  <EditButtonContainer
                                    onClick={() => removeCompetence(instance)}
                                  >
                                    <CrossDelete/>
                                  </EditButtonContainer>
                                </Top>
                                <Indicators>
                                  {instance.indicators_levels && instance.indicators_levels.length > 0 && 
                                    <Line/>
                                  }
                                  {instance.indicators_levels && instance.indicators_levels.map((indicatorInstance: any) => 
                                    <Indicator key={indicatorInstance.indicator.name}>
                                      <IndicatorDot/>
                                      <IndicatorBody>
                                        <IndicatorHeader>
                                          {indicatorInstance.indicator.name}
                                        </IndicatorHeader>
                                        <Select
                                          defaultValue={
                                            reverseLevels[get_level_by_score(indicatorInstance.score, indicatorInstance.indicator.gradations)]
                                          }
                                          variant='unstyled'
                                          placeholder='Выберите уровень'
                                          _hover={{ bg: '#eeeeee' }}
                                          style={{
                                            fontSize: '12px',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                          }}
                                          onChange={(e) => updateCompetence(
                                            e.target.value,
                                            false,
                                            instance.competence.name,
                                            indicatorInstance.indicator.name,
                                          )}
                                        >
                                          {Object.keys(levels).map((level: any) => 
                                            <option key={level}>{level}</option>
                                          )}
                                        </Select>
                                      </IndicatorBody>
                                    </Indicator>
                                  )}
                                </Indicators>
                              </Competence>
                            )}
                          </Competences>
                        :
                        <EmptyBlock>
                          Отсутствуют
                        </EmptyBlock>
                      }
                    </CompetencesWrapper>
                    <ErrorBlock
                      style={{
                        color: '#F83C3C',
                        height: errorMessages.length > 0 ? String(18 * errorMessages.length) + 'px' : '0px',
                        opacity: errorMessages.length > 0 ? '1' : '0',
                        margin: errorMessages.length > 0 ? '16px 0' : '8px 0',
                      }}
                    >
                      {errorMessages.map((message: any) => 
                        <div key={message}>
                          {message}
                        </div>
                      )}
                      
                    </ErrorBlock>
                    <CompetencesWrapper>
                      <p>
                        Доступные компетенции компании
                      </p>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents='none'
                        >
                          <SearchIcon
                            color='var(--addable-gray)'
                            width={15}
                            height={15}
                          />
                        </InputLeftElement>
                        <Input
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value)}
                          color='messenger'
                          height={'36px'}
                          variant='flushed'
                          placeholder='Поиск по компетенциям компании'
                        />
                      </InputGroup>
                      {availableCompetences?.length > 0 ?
                        <Competences
                          style={{
                            opacity: isLoading ? '.3' : '1',
                          }}
                        >
                          {availableCompetences.map((availableCompetence: any) => 
                            <AvailableCompetence
                              key={availableCompetence.id}
                            >
                              <Top>
                                <TopBody>
                                  <h5>{availableCompetence.name}</h5>
                                </TopBody>
                                <EditButtonContainer
                                  onClick={() => addCompetence(availableCompetence)}
                                >
                                  <CrossAdd
                                    color='#000000'
                                  />
                                </EditButtonContainer>
                              </Top>
                              <Indicators>
                                {availableCompetence.indicators.length > 0 && 
                                  <Line/>
                                }
                                {availableCompetence.indicators.map((indicator: any) => 
                                  <Indicator key={indicator.name}>
                                    <IndicatorDot/>
                                    <IndicatorBody>
                                      <IndicatorHeader>
                                        {indicator.name}
                                      </IndicatorHeader>
                                      <IndicatorTest>
                                        {indicator.test}
                                      </IndicatorTest>
                                    </IndicatorBody>
                                  </Indicator>
                                )}
                              </Indicators>
                            </AvailableCompetence>
                          )}
                        </Competences>
                        :
                        <EmptyBlock
                          style={{
                            opacity: isLoading ? '.3' : '1',
                          }}
                        >
                          Не найдены
                        </EmptyBlock>
                      }
                    </CompetencesWrapper>
                  </CompetencesSection>
                  :
                  <SkillsSection>
                    <FormControl>
                      <FormLabel>Hard skills *</FormLabel>
                      <InputGroup>
                        <Input
                          value={searchHardTerm}
                          onChange={e => setSearchHardTerm(e.target.value)}
                          color='messenger'
                          height={'28px'}
                          variant='flushed'
                          placeholder='Введите значение'
                        />
                      </InputGroup>
                    </FormControl>
                    {searchHardTerm &&
                      <SkillsWrapper>
                        <SkillList>
                          {hardSkills.map((skill: any) =>
                            <Skill title={skill?.name} key={skill?.id}>
                              <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                            </Skill>
                          )}
                        </SkillList>
                        {hardSkills.length === 0 &&
                          <Skill title={'Новый'}>
                            <SkillButton type='button' onClick={() => {
                              addSkill({
                                id: Date.now(),
                                name: searchHardTerm,
                                type: "Hard"
                              })
                              setSearchHardTerm('')
                            }}>+</SkillButton>
                          </Skill>
                        }
                      </SkillsWrapper>
                    }
                    <FormControl>
                      <FormLabel>Soft skills *</FormLabel>
                      <InputGroup>
                        <Input
                          value={searchSoftTerm}
                          onChange={e => setSearchSoftTerm(e.target.value)}
                          color='messenger'
                          height={'28px'}
                          variant='flushed'
                          placeholder='Введите значение'
                        />
                      </InputGroup>
                    </FormControl>
                    {searchSoftTerm &&
                      <SkillsWrapper>
                      <SkillList>
                        {softSkills && softSkills.map((skill: any) =>
                          <Skill title={skill?.name} key={skill?.id}>
                            <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                          </Skill>
                        )}
                      </SkillList>
                      {softSkills.length === 0 &&
                        <Skill title={'Новый'}>
                          <SkillButton type='button' onClick={() => {
                            addSkill({
                              id: Date.now(),
                              name: searchSoftTerm,
                              type: "Soft"
                            })
                            setSearchSoftTerm('')
                          }}>+</SkillButton>
                        </Skill>
                      }
                      </SkillsWrapper>
                    }
                    <SkillsWrapper>
                      <SkillList>
                        {skills && skills.map((skill: any) => 
                          <Skill title={skill.name} key={skill.id}>
                            <SkillButton type='button' onClick={() => removeSkill(skill)}>-</SkillButton>
                          </Skill>
                        )}
                        {skills.length < 1 &&
                          <NoData>
                            У должности нет навыков
                          </NoData>
                        }
                      </SkillList>
                    </SkillsWrapper>
                  </SkillsSection>
                }
              </Container>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
                isLoading={isAwaiting}
              >
                Добавить должность
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
}
