import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StepsSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px 16px 4px 4px;
  border: 1px solid #E6E6E6;
`;

export const StepsSectionTop = styled.h5`
  padding: 16px 16px 0 16px;
`;

export const ListWrapper = styled.div`
  width: 100%;
  padding: 16px;
  overflow: auto;
`;

export const List = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 16px 0 0;
  gap: 8px;
`;

export const EmptyBlock = styled.div`
    color: gray;
    transition: .3s;
`;
