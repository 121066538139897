import React, { useState, useEffect } from 'react';

import {
  Competence,
  Top,
  TopBody,
  ArrowContainer,
  CompetenceName,
  CompetenceLevel,
  Line,
  Indicators,
  Indicator,
  IndicatorHeader,
  IndicatorLevel,
  IndicatorTop,
} from './styles';

import { ProfileLevelState } from '@/widgets';
import { ArrowOpen } from '@/UI';
import { Margin } from '@mui/icons-material';
import { fontWeight } from '@mui/system';

export interface ResizeableCompetenceCardProps {
  instance: any;
  size: string;
}

export const ResizeableCompetenceCard: React.FC<ResizeableCompetenceCardProps> = ({ instance, size }) => {
  const [isOpen, setIsOpen] = useState(false);

  const styles: any = {
    small: {
      competence: {
        width: '100%',
        borderRadius: '10px',
        boxShadow: 'inset 0 0 0 1px #E6E6E6',
      },
      top: {
        padding: '10px',
        gap: '10px',
        fontSize: '12px',
      },
      topBody: {
        gap: '0px',
      },
      competenceName: {
        fontWeight: '600',
        fontSize: '12px',
      },
      line: {
        margin: '0 0 10px 0',
      },
      indicators: {
        height: isOpen ? String(
          instance.indicators_levels.length * 33 + (instance.indicators_levels.length - 1) * 10 + 10
        ) + 'px' : '0px',
        opacity: isOpen ? '1' : '0',
        padding: isOpen ? '0 10px 10px 10px' : '0 10px 0 10px',
        gap: '10px',
      },
      indicator: {
        gap: '3px',
      },
      indicatorHeader: {
        fontSize: '12px',
        fontWeight: '400',
      },
    },
    large: {
      competence: {
        borderRadius: '16px',
      },
      top: {
        padding: '16px',
        fontSize: '16px',
      },
      topBody: {
        gap: '5px',
      },
      competenceName: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '18px',
      },
      line: {
        margin: '0 0 16px 0',
      },
      indicators: {
        height: isOpen ? String(
          instance.indicators_levels.length * 39.5 + (instance.indicators_levels.length - 1) * 16 + 16
        ) + 'px' : '0px',
        opacity: isOpen ? '1' : '0',
        padding: isOpen ? '0 16px 16px 16px' : '0 16px 0 16px',
        gap: '16px',
      },
      indicator: {
        gap: '5px',
      },
    }
  }
  const largeStyles: any = {
  }

  const enRusMap: any = {
    'Beginner': 'Слабый',
    'Intermediate': 'Уверенный',
    'Master': 'Сильный',
  };

  function get_competence_level(
    grades: any,
    target_indicators_levels: any,
  ) {
    const level_gradation_mapping: any = {
      Beginner: 0,
      Intermediate: 1,
      Master: 2,
    };

    try {
      let target_indicators_levels_mapping: any = {};

      for (const target_indicator_level of target_indicators_levels) {
        target_indicators_levels_mapping[
          target_indicator_level.indicator.name
        ] = target_indicator_level.score ? get_level_by_score(
          target_indicator_level.score,
          target_indicator_level.indicator.gradations,
        ) : target_indicator_level.level
      }

      let competence_level: string = 'Beginner';

      const grades_array = [
        ...grades.filter(
          (grade: any) => {
            if (grade.name === 'Beginner') {
              return grade;
            }
          }
        ),
        ...grades.filter(
          (grade: any) => {
            if (grade.name === 'Intermediate') {
              return grade;
            }
          }
        ),
        ...grades.filter(
          (grade: any) => {
            if (grade.name === 'Master') {
              return grade;
            }
          }
        ),
      ];

      for (const grade of grades_array) {
          let indicators_levels_mapping: any = {};

          for (const indicator_level of grade.indicators_levels) {
            indicators_levels_mapping[
                indicator_level.indicator.name
            ] = indicator_level.level;
          }

          let level_flag: boolean = true;

          for (const indicator_name of Object.keys(indicators_levels_mapping)) {
              if (level_gradation_mapping[
                target_indicators_levels_mapping[indicator_name]
                ] < level_gradation_mapping[indicators_levels_mapping[indicator_name]]
              ) {
                level_flag = false;
                break;
              }
          }

          if (!level_flag) {
            break
          }

          competence_level = grade.name;
      }
      
      return competence_level;
    }
    catch(e) {
      console.log(e);
      return '';
    }
  }

  function get_level_by_score(score: number, gradations: any) {
    const level: string = 'Master';

    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    }

    for (let i = 0; i < gradations.length; i++) {
      if (score < gradations[i]) {
        return gradation_level_mapping[i];
      }
    }

    return level;
  }

  return (
    <Competence
      style={styles[size]['competence']}
    >
      <Top
        onClick={() => setIsOpen(!isOpen)}
        style={styles[size]['top']}
      >
        <TopBody
          style={styles[size]['topBody']}
        >
          <CompetenceName
            style={styles[size]['competenceName']}
          >
            {instance.competence.name}
          </CompetenceName>
          <CompetenceLevel>
            {enRusMap[
              instance.level ?
                instance.level
                :
                get_competence_level(
                  instance.competence.grades,
                  instance.indicators_levels,
                )
            ]}
          </CompetenceLevel>
        </TopBody>
        {instance.indicators_levels &&
          <ArrowContainer
            style={{
              transform: isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)',
            }}
          >
            <ArrowOpen/>
          </ArrowContainer>
        }
      </Top>
      {instance.indicators_levels && instance.indicators_levels.length > 0 && 
        <div
          style={{
            padding: size === 'small' ? '0 10px' : '0 16px',
          }}
        >
          <Line
            style={
              size === 'small' ? 
              {
                margin: isOpen ? '0 0 10px 0' : '0',
                height: isOpen ? '1px' : '0px',
                opacity: isOpen ? '1' : '0',
              }
            :
              {
                margin: isOpen ? '0 0 16px 0' : '0',
                height: isOpen ? '1px' : '0px',
                opacity: isOpen ? '1' : '0',
              }
          }
          />
        </div>
      }
      {instance.indicators_levels && 
        <Indicators
          style={styles[size]['indicators']}
        >
          {instance.indicators_levels.map((indicatorInstance: any) => 
            <Indicator
              key={indicatorInstance.indicator.name}
              style={styles[size]['indicator']}
            >
              <IndicatorTop>
                <IndicatorHeader
                  style={styles[size]['indicatorHeader']}
                >
                  {indicatorInstance.indicator.name}
                </IndicatorHeader>
                <IndicatorLevel
                  style={styles[size]['indicatorLevel']}
                >
                  {
                    enRusMap[
                      indicatorInstance.score ? get_level_by_score(
                        indicatorInstance.score,
                        indicatorInstance.indicator.gradations,
                      ) : indicatorInstance.level
                    ]
                  }
                </IndicatorLevel>
              </IndicatorTop>
              <ProfileLevelState
                instance={indicatorInstance}
                size={size}
              />
            </Indicator>
          )}
        </Indicators>
      }
    </Competence>
  )
}




