import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #E6E6E6;
`;

export const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Description = styled.div`
    height: fit-content;
    max-height: 64px;
    overflow: hidden;
`;

export const Text = styled.p`
    font-size: 12px;
    line-height: 16px;
`;

export const CollapseButton = styled.button`
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--main-purple);
    font-size: 12px;
    font-weight: 600;
    position: relative;
    z-index: 1;
`;

export const EditButton = styled.button`
  transition: .3s;
  padding: 3px 7px;
  border-radius: 4px;
  text-align: left;
  &:hover {
    background-color: #EAEAEA;
  }
`;

export const EditOptionButton = styled.button`
  transition: .3s;
  padding: 3px 7px;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  &:hover {
    background-color: #EEEEEE;
  }
`;

export const Skills = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
`;

export const SkillsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const SkillsTitle = styled.h5`
    font-size: 12px;
    font-weight: 600;
`;

export const Competences = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;
