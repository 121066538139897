import React, { useState, useEffect } from 'react';
import {
  List,
  EmptyBlock,
} from './styles';
import { getStepsBySpecialization } from '../../api';
import careerState from '../../store/careerState';
import { CareerCard } from '../CareerCard/CareerCard';
import { observer } from 'mobx-react-lite';

export const SpecializationVariants: React.FC = observer(() => {
  const [isAwaiting, setIsAwaiting] = useState(false);
  const [selected, setSelected] = useState<any>();
  const vacancy = JSON.parse(localStorage.getItem('user'))?.active_vacancy;
  const [selectedCareer, setSelectedCareer] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  function handleGetSteps() {
    setIsAwaiting(true);
    getStepsBySpecialization()
      .then((data: any) => {
        careerState.setStepsBySpecialization([vacancy]);
        careerState.setSpecializationCareers(data.data.steps);
        data.data.steps.forEach((steps: any) => {
          steps.forEach((newStep: any) => {
            let flag = true;
            careerState.stepsBySpecialization.forEach((step: any) => {
              if (step.grade === newStep.grade && step.title === newStep.title) {
                flag = false
              }
            })
  
            if (flag) {
              careerState.setStepsBySpecialization([...careerState.stepsBySpecialization, newStep]);
            }
          });
        });
        setErrorMessage('');
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage(e.response.data['error']);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }

  useEffect(() => {
    if (careerState.stepsBySpecialization.length === 0) {
      handleGetSteps();
    }
  }, [])

  useEffect(() => {
    careerState.specializationCareers.forEach((career: any) => {
      career.forEach((step: any) => {
        if (step.title === selected?.title) {
          setSelectedCareer(career);
        }
      });
    });
  }, [selected])

  useEffect(() => {
    careerState.setSelectedStepsBySpecialization(selectedCareer);
  }, [selectedCareer])

  return (
    <>
      {isAwaiting && <EmptyBlock>Формирование направлений развития...</EmptyBlock>}
      {careerState.stepsBySpecialization.length > 1 ?
        <List>
          {careerState.stepsBySpecialization.slice(1).map((step: any, index) =>
            <>
            {step.grade === 'Lead' &&   
            <button
              onClick={() => {
                setSelected(step);
              }}
              style={{ textAlign: 'left' }}
            >                      
              <CareerCard
                step={step}
                isActive={selected?.title && selected?.title === step?.title ? true : false}
                key={index}
              />
            </button>
            }
            </>
          )}
        </List>
        :
        <EmptyBlock>
          {errorMessage ? errorMessage : 'Повторите запрос позже'}
        </EmptyBlock>
      }
    </>
  )
});
