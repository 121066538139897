import axios from 'axios';
import { ICompetence } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const createCompetence = async (competence: ICompetence) => {
  const response = await axios<ICompetence>({
      url: `${API}/competences/`,
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: competence,
  })
  
  return response
}

export const editCompetence = async (competence: ICompetence) => {
  const response = await axios<ICompetence>({
      url: `${API}/competences/${competence.id}/`,
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: competence,
  })
  
  return response
} 

export const deleteCompetence = async (competenceId: number) => {
  const response = await axios<ICompetence[]>({
      url: `${API}/competences/${competenceId}/`,
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return response
}

export const createCompetenceVacancy = async (instances: any, vacancyId: number) => {
  let data: any = [];
  if (instances.length > 0) {
    data = instances.map((instance: any) => {
      return {
        competence: instance.competence,
        vacancy: vacancyId,
        indicators_levels: instance.indicators_levels,
      }
    });
  }
  else {
    data = [
      {
        competence: {},
        vacancy: vacancyId,
        indicators_levels: [],
      },
    ]
  }

  console.log(data);
  
  const response = await axios<ICompetence>({
    url: `${API}/competence-vacancy/`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Headers": "*",
        "Authorization": `Bearer ${access}`,
    },
    data: data,
  })

  return response;
}

export const updateCompetenceVacancy = async (
  vacancyId: number,
  instances: any,
) => {
  let data: any = [];
  if (instances.length > 0) {
    data = instances.map((instance: any) => {
      return {
        competence: instance.competence,
        vacancy: vacancyId,
        level: instance.level,
        indicators_levels: instance.indicators,
      }
    });
  }
  else {
    data = [
      {
        competence: {},
        vacancy: vacancyId,
        level: '',
        indicators_levels: [],
      },
    ]
  }
  const response = await axios<ICompetence>({
    url: `${API}/competence-vacancy/`,
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Headers": "*",
        "Authorization": `Bearer ${access}`,
    },
    data: instances,
  })

  return response;
}

export const deleteCompetenceVacancy = async (instances: any) => {
  const response = await axios<ICompetence>({
    url: `${API}/competence-vacancy/`,
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Headers": "*",
        "Authorization": `Bearer ${access}`,
    },
    data: instances,
  })

  return response;
}
