import React, { useEffect, useState } from 'react';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  InputLeftElement,
  Input,
  Select,
  Button,
  Switch
} from '@chakra-ui/react';

import {
  Container,
  Competences,
  Competence,
  AvailableCompetence,
  Top,
  TopBody,
  EditButtonContainer,
  CompetenceButton,
  Line,
  Indicators,
  Indicator,
  IndicatorBody,
  IndicatorDot,
  IndicatorHeader,
  IndicatorTest,
  EmptyBlock,
  ErrorBlock,
} from './styles';

import {
  searchCompetences,
  editCompetences,
} from '@/features/employee';
import { getCompetences } from '@/entities/competence';

import { IUser, useDebounce } from '@/shared';
import SearchIcon from '@/assets/icons/search.svg';
import { Skill } from '@/shared';
import styled from 'styled-components';
import { CrossAdd, CrossDelete } from '@/UI';

export interface EditCompetencesPopupProps {
  user: IUser;
  isOpen: boolean;
  onClose: () => void;
}

export const EditCompetencesPopup: React.FC<EditCompetencesPopupProps> = ({ user, isOpen, onClose }) => {
//   const current_user = JSON.parse(localStorage.getItem('user'));

  const [competences, setCompetences] = useState([]);
  const [availableCompetences, setAvailableCompetences] = useState([]);

  const [isAwaiting, setIsAwaiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const messageWaitTime: number = 5000;

  const [searchQuery, setSearchQuery] = useState('');

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const levels: any = {
    'Слабый': 'Beginner',
    'Уверенный': 'Intermediate',
    'Сильный': 'Master',
  }
  const reverseLevels: any = {
    'Beginner': 'Слабый',
    'Intermediate': 'Уверенный',
    'Master': 'Сильный',
  }

  function get_level_by_score(score: number, gradations: any) {
    if (!score || !gradations) {
      return '';
    }

    const level: string = 'Master';

    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    }

    for (let i = 0; i < gradations.length; i++) {
      if (score < gradations[i]) {
        return gradation_level_mapping[i];
      }
    }

    return level;
  }

  function addCompetence(addedCompetence: any) {
    setAvailableCompetences(
      availableCompetences.filter(
        (competence: any) => {
          if (addedCompetence.name !== competence.name) {
            return competence;
          }
        }
      )
    );

    let modifiedAddedCompetence: any = {
      competence: addedCompetence,
      user: user.id,
      level: '',
      indicators_levels: [],
    };

    for (const indicator of addedCompetence.indicators) {
      modifiedAddedCompetence.indicators_levels.push(
        {
          indicator: indicator,
          level: '',
        }
      )
    }
    console.log(modifiedAddedCompetence);

    setCompetences([...competences, modifiedAddedCompetence]);
  }

  function removeCompetence(removedCompetence: any) {
    setCompetences(
      competences.filter(
        (instance: any) => {
          if (removedCompetence.competence.name !== instance.competence.name) {
            return instance;
          }
        }
      )
    )
  }

  function updateCompetence(
    value: string,
    isCompetence: boolean,
    name: any,
    indicatorName: any = '',
  ) {
    setCompetences(
      competences.map((instance) => {
        let instanceToReturn: any = instance;
        if (instance.competence.name === name) {
          let updatedInstance: any;

          if (isCompetence) {
            updatedInstance = instance;
            updatedInstance.level = levels[value];
          }
          else {
            updatedInstance = instance;
            let updatedIndicatorsInstances = instance.indicators_levels;

            updatedIndicatorsInstances = updatedIndicatorsInstances.map(
              (indicatorInstance: any) => {
                let indicatorInstanceToReturn: any = indicatorInstance;

                if (indicatorInstance.indicator.name === indicatorName) {
                  let updatedIndicatorInstance = indicatorInstance;
                  updatedIndicatorInstance.level = levels[value];
                  indicatorInstanceToReturn = updatedIndicatorInstance;
                }

                return indicatorInstanceToReturn;
              }
            )
          }
          instanceToReturn = updatedInstance;
        }
        return instanceToReturn;
      })
    );
  }

  function handleGetCompetences() {
    setIsLoading(true);

    getCompetences()
      .then((response: any) => {
        if (response?.status === 200) {
          let competencesToSet: any = [];
          
          for (const competence of response.data) {
            if (
                !competences.map((instance: any) => {return instance.competence.name;}).includes(
                competence.name
              )
            ) {
              competencesToSet.push(competence);
            }
          }
          setAvailableCompetences(competencesToSet);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  function handleSearchCompetences() {
    setIsLoading(true);

    searchCompetences(debouncedSearchQuery)
      .then((response: any) => {
        if (response?.status === 200) {
          let competencesToSet: any = [];
          for (const competence of response.data) {
            if (!competences.map(
              (instance) => {
                return instance.competence.name
              }
            ).includes(competence.name)) {
              competencesToSet.push(competence);
            }
          }
          setAvailableCompetences(competencesToSet);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const competenceValidator = {
    checkValidation(instance: any) {
      let tempErrorMessages: any[] = [];

      for (const indicatorInstance of instance.indicators_levels) {
        if (indicatorInstance.level === '') {
          setErrorMessages(['']);
          const message = 'Укажите уровни индикаторов';
          if (!tempErrorMessages.includes(message)) {
            tempErrorMessages.push(message);
          }
        }
      }

      setErrorMessages(tempErrorMessages);

      if (tempErrorMessages.length > 0) {
        this.timeoutId = setTimeout(() => {
          setErrorMessages([]);
        }, messageWaitTime);
        return false;
      }

      return true;
    },

    validateCompetence(instance: any) {
      if (this.timeoutId !== undefined) {
        clearTimeout(this.timeoutId);
      }
      return this.checkValidation(instance);
    }
  }

  useEffect(() => {
    let userCompetences = user?.competences ? user?.competences : [];
    if (userCompetences.length > 0) {
      for (const instance of userCompetences) {
        instance.indicators_levels = instance.indicators_levels.map(
          (indicatorInstance: any) => {
            const updatedIndicator: any = indicatorInstance;
            updatedIndicator.level = get_level_by_score(
              indicatorInstance.score,
              indicatorInstance.indicator.gradations,
            );
            return updatedIndicator;
          }
        )
      }
    }
    setCompetences(user?.competences ? user?.competences : []);
  }, []);

  useEffect(() => {
    if (debouncedSearchQuery !== '') {
      handleSearchCompetences();
    }
    else {
      handleGetCompetences();
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setIsLoading(true);
  }, [searchQuery])

  useEffect(() => {
    handleGetCompetences();
  }, [competences]);

  function get_score_by_level(level: string, gradations: any) {
    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    };

    let score: number = 100;

    for (let i = 0; i < gradations.length; i++) {
      if (gradation_level_mapping[i] === level) {
        return gradations[i] - 1;
      }
    }

    return score;
  }

  function formCompetenceUserInstance(instance: any, userId: number) {
    const response: any = {
      competence: instance.competence.id,
      user: userId,
      indicators_levels: instance.indicators_levels.map(
        (indicator_level: any) => {
          return {
            indicator: indicator_level.indicator.id,
            level: indicator_level.level,
            score: get_score_by_level(
              indicator_level.level,
              indicator_level.indicator.gradations,
            ),
          }
        }
      ),
    }
    return response;
  }

  function handleSubmit(event: any) {
    setIsAwaiting(true);
    event.preventDefault();
    
    let competencesAreValidated: boolean = true;

    for (const competence of competences) {
      competencesAreValidated = competenceValidator.checkValidation(competence);
    }
    if (competencesAreValidated) {
      const competencesUsers = competences.map(
        (instance: any) => {
          return formCompetenceUserInstance(instance, user.id);
        }
      )
      console.log(competencesUsers);
      editCompetences(user.id, competencesUsers)
        .then((response: any) => {
          if (response?.status === 201) {
            onClose();
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsAwaiting(false);
        })
    }
    else {
      setIsAwaiting(false);
    }
  }

  return (
    <Modal size={'2xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent borderRadius={16}>
          <ModalHeader alignItems={'center'}>
            <h3 style={{ textAlign: 'center' }}>Редактирование компетенций</h3>
          </ModalHeader>
          <ModalCloseButton top={'14px'} />
          <ModalBody
            padding='20px'
            display='flex'
            flexDirection='column'
            gap='16px'
            borderTop='1px solid var(--addable-gray)'
            borderBottom='1px solid var(--addable-gray)'
          >

          <Container>
            <h5>
              Компетенции сотрудника
            </h5>
            {competences && competences.length ?
              <Competences>
                {competences.map((instance) => 
                  <Competence>
                    <Top>
                      <TopBody>
                        <div>
                          <h5>{instance.competence.name}</h5>
                        </div>
                      </TopBody>
                      <EditButtonContainer
                        onClick={() => removeCompetence(instance)}
                      >
                        <CrossDelete/>
                      </EditButtonContainer>
                    </Top>
                    <Indicators>
                      {instance.indicators_levels && instance.indicators_levels.length > 0 && 
                        <Line/>
                      }
                      {instance.indicators_levels && instance.indicators_levels.map((indicatorInstance: any) => 
                        <Indicator key={indicatorInstance.indicator.name}>
                          <IndicatorDot/>
                          <IndicatorBody>
                            <IndicatorHeader>
                              {indicatorInstance.indicator.name}
                            </IndicatorHeader>
                            <Select
                              defaultValue={
                                reverseLevels[
                                  get_level_by_score(
                                    indicatorInstance.score,
                                    indicatorInstance.indicator.gradations,
                                  )
                                ]
                              }
                              variant='unstyled'
                              placeholder='Выберите уровень'
                              _hover={{ bg: '#eeeeee' }}
                              style={{
                                fontSize: '12px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                              }}
                              onChange={(e) => updateCompetence(
                                e.target.value,
                                false,
                                instance.competence.name,
                                indicatorInstance.indicator.name,
                              )}
                            >
                              {Object.keys(levels).map((level: any) => 
                                <option key={level}>{level}</option>
                              )}
                            </Select>
                          </IndicatorBody>
                        </Indicator>
                      )}
                    </Indicators>
                  </Competence>
                )}
              </Competences>
              :
              <EmptyBlock>
                Не найдены
              </EmptyBlock>
            }
            <ErrorBlock
              style={{
                color: '#F83C3C',
                height: errorMessages.length > 0 ? String(18 * errorMessages.length) + 'px' : '0px',
                opacity: errorMessages.length > 0 ? '1' : '0',
                margin: errorMessages.length > 0 ? '12px 0 4px 0' : '0',
              }}
            >
              {errorMessages.map((message: any) => 
                <div key={message}>
                  {message}
                </div>
              )}
              
            </ErrorBlock>
          </Container>
          <Container>
            <h5>
              Доступные компетенции компании
            </h5>
            <InputGroup
              padding={'4px 0'}
              margin={'16px 0 0 0'}
            >
              <InputLeftElement
                pointerEvents='none'
              >
                <SearchIcon
                  color='var(--addable-gray)'
                  width={15}
                  height={15}
                />
              </InputLeftElement>
              <Input
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                color='messenger'
                height={'28px'}
                variant='flushed'
                placeholder='Поиск по компетенциям компании'
              />
            </InputGroup>
            {availableCompetences && availableCompetences.length ?
              <Competences
                style={{
                  opacity: isLoading ? '.3' : '1',
                }}
              >
                {availableCompetences.map((availableCompetence: any) => 
                  <AvailableCompetence
                    key={availableCompetence.id}
                  >
                    <Top>
                      <TopBody>
                        <h5>{availableCompetence.name}</h5>
                      </TopBody>
                      <EditButtonContainer
                        onClick={() => addCompetence(availableCompetence)}
                      >
                        <CrossAdd
                          color='#000000'
                        />
                      </EditButtonContainer>
                    </Top>
                    <Indicators>
                      {availableCompetence.indicators.length > 0 && 
                        <Line/>
                      }
                      {availableCompetence.indicators.map((indicator: any) => 
                        <Indicator key={indicator.name}>
                          <IndicatorDot/>
                          <IndicatorBody>
                            <IndicatorHeader>
                              {indicator.name}
                            </IndicatorHeader>
                            <IndicatorTest>
                              {indicator.test}
                            </IndicatorTest>
                          </IndicatorBody>
                        </Indicator>
                      )}
                    </Indicators>
                  </AvailableCompetence>
                )}
              </Competences>
              :
              <EmptyBlock>
                Не найдены
              </EmptyBlock>
            }
            </Container>
          </ModalBody>
          <ModalFooter gap='8px' display='flex'>
            <Button
              onClick={onClose}
              type='button'
              width='100%'
              variant='outline'
              color='var(--main-purple)'
              borderRadius='8px'
            >
              Отмена
            </Button>
            <Button
              type='submit'
              width='100%'
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: '#2D53DA' }}
              color='#fff'
              isLoading={isAwaiting}
            >
              Сохранить изменения
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
