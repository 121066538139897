import React, { useState, useEffect } from 'react';
import { Card, ICompetence, IUser } from '@/shared';
import {
  Head,
  H6,
  Competences,
  EmptyBlock,
} from './styles';
import EditIcon from '@/assets/icons/edit.svg';
import { Button, useDisclosure } from '@chakra-ui/react';
import { EditCompetencesPopup } from '@/features/employee';
import { createGlobalStyle } from 'styled-components';
import { ResizeableCompetenceCard } from '@/widgets';

export interface MyCompetencesProps {
  user: IUser;
}

const GlobalStyle = createGlobalStyle`
  .custom-skill-container > div {
    background: none !important;
    border: none !important;
  }
`;

export const MyCompetences: React.FC<MyCompetencesProps> = ({ user }) => {
    const current_user = JSON.parse(localStorage.getItem('user'));

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <>
        <GlobalStyle />
          <Card>
            <Head>
              <h3>Компетенции</h3>
              {current_user.role !== 'User' && 
                <Button
                  onClick={onOpen}
                  border='1px var(--addable-gray) solid'
                  borderRadius='8px'
                  bg='var(--main-white)'
                  leftIcon={<EditIcon color='var(--main-purple)' />}
                >
                  <H6>Редактировать</H6>
                </Button>
              }
            </Head>

            {user && user.competences ? 
              <Competences>
                {user.competences.map((instance: any) => 
                  <ResizeableCompetenceCard
                    key={instance.competence.id}
                    instance={instance}
                    size='large'
                  />
                )}
              </Competences>
            :
              <EmptyBlock>
                Не найдены
              </EmptyBlock>
            }
          </Card>
        <EditCompetencesPopup user={user} isOpen={isOpen} onClose={onClose}/>
      </>
    )
}
