import React, { useState, useRef } from 'react';
import { Card } from '@/shared';
import {
  Container,
  Top,
  Description,
  Text,
  CollapseButton,
  Line,
} from './styles';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { removeExclamation } from '@/shared';

export interface CareerCardProps {
  step: any;
  isActive?: boolean;
}

export const CareerCard: React.FC<CareerCardProps> = ({ step, isActive }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const descriptionRef = useRef(null);

  return (
    <Container>
      <Card isActive={isActive}>
        <Top>
          <h5 
            style={{
              color: isActive ? 'var(--main-white)' : '',
            }}
          >
            {removeExclamation(step?.title)} {step?.name}
          </h5>
        </Top>
        <Line/>
        <Description
          style={{
            maxHeight: isCollapsed ? '64px' : String(descriptionRef.current.offsetHeight) + 'px',
            transition: '.3s',
        }}
        >
          <Text
            style={{
              color: isActive ? 'var(--main-white)' : '',
            }}
            ref={descriptionRef}
          >
            {step?.description}
          </Text>
        </Description>
        {step?.description &&
            <CollapseButton
                onClick={() => setIsCollapsed(!isCollapsed)}
                style={{
                  color: isActive ? 'var(--main-white)' : '',
                }}
            >
                {isCollapsed ? <>Развернуть</> : <>Свернуть</>}
                <CollapseIcon
                    style={{
                        color: isActive ? 'var(--main-white)' : '',
                        transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                    }}
                    width={10}
                    height={5}
                />
            </CollapseButton>
        }
      </Card>
    </Container>
  )
}
