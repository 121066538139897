import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background: var(--main-lightgray);
    border-radius: 16px;
    box-shadow: inset 0 0 0 1px var(--addable-gray);
    transition: .3s;
`;
