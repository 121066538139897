import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  FormErrorMessage,
  FormControl,
  Button
} from '@chakra-ui/react';
import SearchIcon from '@/assets/icons/search.svg';
import {
  Container,
  Buttons,
  CourseButton,
  Form,
} from './styles';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { useDebounce } from '@/shared';
import { searchCourses } from '@/features/employee';
import coursesState from '@/entities/course/store/coursesState';
import { addStepikCourses } from '@/features/user';
import { getStepikCourses } from '@/features/employee';
import { getCourseById, getStepikData, logoutStepik } from '@/entities/course';
import { getEmployeeById } from '@/features/employee';
import { getActiveUserCourses } from '@/entities/course';
import toast, { Toaster } from 'react-hot-toast';

export const CoursesTopbar: React.FC = () => {
  const querySearch = window.location.search.substr(1).split('=')[1] ? decodeURI(window.location.search.substr(1).split('=')[1]) : '';

  const user = JSON.parse(localStorage.getItem('user'));
 
  const [searchTerm, setSearchTerm] = useState(querySearch ? querySearch : '');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    getEmployeeById(user?.id)
      .then((res: any) => {
        setClientId(res.data?.stepik_client_id);
        setClientSecret(res.data?.stepik_client_secret);
      })
      .catch((e) => {
        console.log(e);
      })
  }, [])

  function handleSearch() {
    searchCourses(debouncedSearchTerm)
      .then((res: any) => {
        if (res?.status === 200) {
          coursesState.setCourses(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    handleGetStepikData();
  }, []);

  useEffect(() => {
    if (typeof debouncedSearchTerm === 'string') {
      handleSearch();
    }
  }, [debouncedSearchTerm]);

  const handleLogin = (e: any) => {
    e.preventDefault();
    addStepikCourses(clientId, clientSecret)
      .then((res: any) => {
        setClientId(res.client_id);
        setClientSecret(res.client_secret);
        toast.success("Вы вошли в аккаунт! Активные курсы скоро появятся в соответствующей вкладке");
        // getActiveUserCourses(user?.id)
        //   .then((res) => {
        //     res.data.forEach((el: any) => {
        //       getCourseById(el.course)
        //         .then((res) => {
        //           coursesState.setActiveCourses([...coursesState.activeCourses, res.data]);
        //         })
        //         .catch((e) => {
        //           console.log(e);
        //         })
        //     });
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   })
      })
      .catch(e => {
        toast.error("Вы ранее уже подключили аккаунт stepik");
      })
  }

  function handleGetStepikData() {
    getStepikData()
      .then((res) => {
        console.log(res.data);
      })
      .catch((e: any) => {
        console.log(e);
      })
  }

  function handleLogout(e: any) {
    e.preventDefault();
    logoutStepik()
      .then(res => {
        setClientId('');
        setClientSecret('');
        location.reload();
      })
      .catch((e: any) => {
        console.log(e);
      })
  }

  return (
    <>
      <Container>
          <InputGroup padding={'6px'}>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon
                color='var(--addable-gray)'
                width={18}
                height={18}
              />
            </InputLeftElement>
            <Input
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              color='messenger'
              height={'28px'}
              variant='flushed'
              placeholder='Поиск среди курсов'
            />
          </InputGroup>
          <Buttons>
            <Popover placement='bottom-end'>
              <PopoverTrigger>
                <CourseButton>
                  Stepik
                  <CollapseIcon
                    width={14}
                    height={14}
                    color='var(--main-purple)'
                  />
                </CourseButton>
              </PopoverTrigger>
              <PopoverContent width={'221px'}>
                <PopoverBody>
                  <Form>
                      <Input
                        placeholder='Сlient Id'
                        type='text'
                        size='sm'
                        variant='flushed'
                        value={clientId}
                        onChange={(event) => setClientId(event.target.value)}
                      />
                      <Input
                        placeholder='Сlient Secret'
                        type='text'
                        size='sm'
                        variant='flushed'
                        value={clientSecret}
                        onChange={(event) => setClientSecret(event.target.value)}
                      />
                    <Button
                      width='100%'
                      variant='solid'
                      background='var(--main-purple)'
                      _hover={{ bg: 'var(--hover-purple)' }}
                      color='var(--main-white)'
                      borderRadius='8px'
                      type='submit'
                      onClick={handleLogin}
                    >
                      Войти
                    </Button>
                    <span>
                      Для получения данных перейдите по
                      {' '}
                      <a
                        href='https://stepik.org/oauth2/applications/'
                        target='_blank'
                        style={{ textDecoration: 'underline', color: 'var(--main-purple)' }}
                      >
                        ссылке
                      </a>
                      , чтобы создать приложение
                      Нажмите, чтобы {' '}
                      <button onClick={handleLogout}>
                        <a style={{ textDecoration: 'underline', color: 'var(--main-purple)' }}>выйти</a>
                      </button>
                    </span>
                  </Form>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            {/* <Popover placement='bottom-end'>
              <PopoverTrigger>
                <CourseButton
                  style={{
                    width: '170px'
                  }}
                >
                  Яндекс Практикум
                  <CollapseIcon
                    width={14}
                    height={14}
                    color='var(--main-purple)'
                  />
                </CourseButton>
              </PopoverTrigger>
              <PopoverContent width={'114px'}>
                <PopoverBody>
                  <PopoverButton>Войти</PopoverButton>
                </PopoverBody>
              </PopoverContent>
            </Popover> */}

            {/* <Popover placement='bottom-end'>
              <PopoverTrigger>
                <SearchButton style={{ width: '122px' }}>
                  Найти курс
                  <CollapseIcon
                    width={14}
                    height={14}
                    color='var(--main-white)'
                  />
                </SearchButton>
              </PopoverTrigger>
              <PopoverContent width={'140px'}>
                <PopoverBody>
                  <PopoverButton onClick={handleGetStepikCourses}>Stepik</PopoverButton>
                  <PopoverButton>Яндекс Практикум</PopoverButton>
                </PopoverBody>
              </PopoverContent>
            </Popover> */}

          </Buttons>
      </Container>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
              boxShadow: '0 0 0 1px #EFEFEF',
          }
        }}
      />
    </>
  )
}
