import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '@/widgets';
import {
    Page,
    Board,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { getCompany } from '@/entities/company/api';
import { ICompany } from '@/shared';
import { CompareArrows } from '@mui/icons-material';

const categories = [
    {
        id: 1,
        title: 'Моя компания',
        links: [
            {
                id: 1,
                title: 'Сотрудники',
                to: '/dashboard-hr-employees',
            },
            {
                id: 2,
                title: 'Тесты',
                to: '/dashboard-hr-tests',
            },
            {
                id: 3,
                title: 'Структура',
                to: '/dashboard-hr-structure',
            },
            {
                id: 4,
                title: 'Аналитика',
                to: '/dashboard-hr-analitic',
            },
        ],
    },
    {
        id: 2,
        title: 'Другое',
        links: [
            {
                id: 5,
                title: 'База должностей',
                to: '/dashboard-hr-vacancies',
            },
        ],
    },
];


export const HrLayout: React.FC = () => {
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('user'));
    const company = JSON.parse(localStorage.getItem('company'));

    const competence_system_pages: Array<string> = [
        'dashboard-hr-competence-analytics',
        'dashboard-hr-competences',
    ]
    const competence_system_categories = {
        id: 3,
        title: 'Новое',
        links: [
            {
                id: 6,
                title: 'Аналитика компетенций',
                to: '/dashboard-hr-competence-analytics'
            },
            {
                id: 7,
                title: 'База компетенций',
                to: '/dashboard-hr-competences'
            },
        ]
    }

    useEffect(() => {
        if (company?.competence_system) {
            categories.push(competence_system_categories);
        }

        if (!user) {
          navigate('/');
        }
    
        if (!(user?.role === 'HR manager' || user?.role === 'Super HR manager')) {
          navigate('/');
        }
    }, [])

  return (
    <Page>
        <Navbar categories={categories} />
        <Board>
            <Outlet />
        </Board>
    </Page>
  )
}
