// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KpkMggCuMJKZKX1ZRVkm{width:fit-content;display:flex;align-items:center;padding:2px;gap:2px;border-radius:8px;background-color:var(--addable-gray)}.fCL9pLzPKjzSCV_sO7bZ{height:26px;padding:0 10px;border-radius:7px;color:var(--disabled);transition:.3s}.fCL9pLzPKjzSCV_sO7bZ:hover:not(.ERskYkobrzhwnp0vQyzV){background-color:#f5f5f5}.fCL9pLzPKjzSCV_sO7bZ>*{color:var(--disabled);transition:.3s}.ERskYkobrzhwnp0vQyzV{background-color:var(--main-white)}.ERskYkobrzhwnp0vQyzV>*{color:var(--main-black)}`, "",{"version":3,"sources":["webpack://./src/features/common/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,WAAA,CACA,OAAA,CACA,iBAAA,CACA,oCAAA,CAGJ,sBACI,WAAA,CACA,cAAA,CACA,iBAAA,CACA,qBAAA,CACA,cAAA,CAEA,uDACI,wBAAA,CAIR,wBACI,qBAAA,CACA,cAAA,CAGJ,sBACI,kCAAA,CAGJ,wBACI,uBAAA","sourcesContent":[".container {\n    width: fit-content;\n    display: flex;\n    align-items: center;\n    padding: 2px;\n    gap: 2px;\n    border-radius: 8px;\n    background-color: var(--addable-gray);\n}\n\n.button {\n    height: 26px;\n    padding: 0 10px;\n    border-radius: 7px;\n    color: var(--disabled);\n    transition: .3s;\n\n    &:hover:not(.active) {\n        background-color: #f5f5f5;\n    }\n}\n\n.button > * {\n    color: var(--disabled);\n    transition: .3s;\n}\n\n.active {\n    background-color: var(--main-white);\n}\n\n.active > * {\n    color: var(--main-black);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `KpkMggCuMJKZKX1ZRVkm`,
	"button": `fCL9pLzPKjzSCV_sO7bZ`,
	"active": `ERskYkobrzhwnp0vQyzV`
};
export default ___CSS_LOADER_EXPORT___;
