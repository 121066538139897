import React, { useEffect, useRef, useState } from 'react';
import { Card } from '@/shared';
import { CompetenceCard, getCompetences } from '@/entities/competence';
import { Container } from './styles';
import { EmptyResponse } from '../EmptyResponse/EmptyResponse';
import { getVacancies } from '@/entities/vacanci';
import competencesState from '@/entities/competence/store/competencesState';
import { observer } from 'mobx-react-lite';
import { getCompany } from '@/entities/company/api';

export const CompetencesList: React.FC = observer(() => {
  const [offset, setOffset] = useState(0);
  const [isAwaiting, setIsAwaiting] = useState(false);
  const lastElement = useRef();
  const observer = useRef<IntersectionObserver>();

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  useEffect(() => {
    setIsAwaiting(true);
    getCompetences()
      .then((response) => {
        const competences: any[] = [];
        response.data.forEach((competence: any) => {
          competences.push(competence);
        })
        competencesState.setCompetences(competences);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }, [])

  return (
    <>
      {competencesState.competences.length === 0 ?
        <EmptyResponse/>
        :
        <Container>
          {competencesState.competences.map((competence: any) => 
            <CompetenceCard competence={competence} key={competence.id}/>
          )}
        </Container>
      }
    </>
  )
});
