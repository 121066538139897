import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Divider,
  Select
} from '@chakra-ui/react';
import { getProjectById } from '@/entities/projects';
import { IUser } from '@/shared';
import { Content } from './styles';
import toast, { Toaster } from 'react-hot-toast';
import { sendProjectRequest } from '@/features/projects';

export interface AppointProjectPopupProps {
  isOpen: boolean;
  onClose: () => void;
  projects: any;
  employee: IUser;
}

export const AppointProjectPopup: React.FC<AppointProjectPopupProps> = ({ isOpen, onClose, projects, employee }) => {
  const [projectId, setProjectId] = useState<any>();
  const [roleId, setRoleId] = useState<any>();
  const [project, setProject] = useState<any>();
  const [isRoleDisabled, setIsRoleDisabled] = useState<boolean>(true);

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (projectId) {
      console.log("projectId:", projectId);
      getProjectById(projectId)
        .then((res) => {
          setProject(res.data);
          setIsRoleDisabled(false);
        })
        .catch((e) => {
          console.log(e);
        })
    }
  }, [projectId])

  function handleAppoint() {
    sendProjectRequest(projectId, roleId, user?.id, employee?.id)
      .then((res) => {
        toast.success('Заявка успешно отправлена!');
        onClose();
      })
      .catch((e) => {
        toast.error('Заявка уже была отправлена!');
      })
  }

  return (
    <>
      <Modal size={'4xl'} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Назначить на проект</h3>
            </ModalHeader>

            <ModalCloseButton top={'10px'} size="lg" />

            <Divider />

            <ModalBody>
              <Content>
                <h5>Выберите проект</h5>
                <Select
                  isReadOnly={false}
                  variant='flushed'
                  placeholder='Выберите проект'
                  value={projectId}
                  onChange={e => setProjectId(e.target.value)}
                >
                  {projects?.map((project: any) =>
                    <option key={project.project?.id} value={project.project?.id}>{project.project?.title}</option> 
                  )}
                </Select>

                <h5>Выберите роль на проекте</h5>
                <Select
                  isDisabled={isRoleDisabled}
                  isReadOnly={false}
                  variant='flushed'
                  placeholder='Выберите роль'
                  value={roleId}
                  onChange={e => setRoleId(e.target.value)}
                >
                  {project?.expected_roles?.map((role: any) =>
                    <option key={role?.id} value={role?.id}>{role?.title}</option> 
                  )}
                </Select>
              </Content>
            </ModalBody>

            <Divider />

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>

              <Button
                isDisabled={!(roleId && projectId)}
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
                onClick={handleAppoint}
              >
                Отправить заявку
              </Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
              boxShadow: '0 0 0 1px #EFEFEF',
          }
      }}
      />
    </>
  )
}
