import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const SkillsSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    box-shadow: inset 0 0 0 1px #E6E6E6;
    padding: 16px;
`;

export const CompetencesSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const CompetencesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    box-shadow: inset 0 0 0 1px #E6E6E6;
    padding: 16px;
`;

export const NoData = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    color: #aaaaaa;
    font-size: 13px;
`;

export const Competences = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    flex-wrap: wrap;
    transition: .3s;
`;

export const EmptyBlock = styled.div`
    color: gray;
    transition: .3s;
`;

export const Competence = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 16px;
    border-radius: 16px;
    background-color: #F6F7F7;
    box-shadow: inset 0 0 0 1px #E6E6E6;
`;

export const AvailableCompetence = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 16px;
    border-radius: 16px;
    background-color: #F6F7F7;
    box-shadow: inset 0 0 0 1px #E6E6E6;
`;

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
`;

export const TopBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const EditButtonContainer = styled.div`
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: .3s;
    &:hover {
        background-color: #EAEAEA;
    }
`;

export const CompetenceButton = styled.div`

`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: #E6E6E6;
    margin: 14px 0 5px 0;
`;

export const Indicators = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Indicator = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const IndicatorDot = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #D9DFF4;
`;

export const IndicatorBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const IndicatorHeader = styled.div`
    font-size: 13px;
    font-weight: 600;
`;

export const IndicatorTest = styled.div`
    font-size: 12px;
`;

export const ErrorBlock = styled.div`
  width: 100%;
  transition: .3s;
  display: flex;
  flex-direction: column;
`;
